import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container } from "../theme/global-style"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Container>
      <h1>404: Not Found</h1>
      <p>Página não encontrada.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
